export const ADMIN_COMPANY_URL = '/admin/company';

// export const ADMIN_DEPARTMENT_URL = `${ADMIN_COMPANY_URL}/%s/department`;
// export const ADMIN_DEPARTMENT_ADD_URL = `${ADMIN_DEPARTMENT_URL}/add`;

// export const ADMIN_EMPLOYEE_URL = `${ADMIN_COMPANY_URL}/%s/employee`;
// export const ADMIN_EMPLOYEE_ADD_URL = `${ADMIN_EMPLOYEE_URL}/add`;

const ADMIN_JOB_URL = `${ADMIN_COMPANY_URL}/%s/job`;
// export const ADMIN_JOB_ADD_URL = `${ADMIN_JOB_URL}/add`;
export const ADMIN_JOB_EDIT_URL = `${ADMIN_JOB_URL}/edit/%s`;
export const ADMIN_JOB_CHANGE_APPROVER = `${ADMIN_JOB_URL}/change-approver`;

export const CLIENT_MY_REQUEST_URL = '/client/my-request';

export const APP_URL = '/';

export const LOGIN_URL = '/login';

export const CREATE_REQUEST_URL = '/client/request/create';
export const UPDATE_REQUEST_URL = '/client/request/update/%s';

export const REQUEST_DETAIL_DIRECT_URL = '/client/request-details/%s';

export const MY_TASK_URL = '/client/my-task';
